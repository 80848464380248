import React, { FC } from 'react'

import { Image } from '../Image'

import admissionTickets from './images/admission-tickets.png'
import incomingEnvelope from './images/incoming-envelope.png'
import manShrugging from './images/man-shrugging.png'
import partyPopper from './images/party-popper.png'
import raisingHands from './images/raising-hands.png'
import redHeart from './images/red-heart.png'
import rocket from './images/rocket.png'
import snowCappedMountain from './images/snow-capped-mountain.png'
import sparklingHeart from './images/sparkling-heart.png'
import spiralCalendar from './images/spiral-calendar.png'
import tearOffCalendar from './images/tear-off-calendar.png'
import wavingHand from './images/waving-hand.png'

const EMOJI_IMAGE_MAPPING = {
  'admission-tickets': admissionTickets,
  'incoming-envelope': incomingEnvelope,
  'man-shrugging': manShrugging,
  'party-popper': partyPopper,
  'raising-hands': raisingHands,
  'red-heart': redHeart,
  'snow-capped-mountain': snowCappedMountain,
  'sparkling-heart': sparklingHeart,
  'spiral-calendar': spiralCalendar,
  'tear-off-calendar': tearOffCalendar,
  'waving-hand': wavingHand,
  rocket: rocket,
} as const

export const EmojiIcon: FC<{
  size?: number
  name: keyof typeof EMOJI_IMAGE_MAPPING
}> = ({ size = 5, name }) => {
  return <Image size={size} source={EMOJI_IMAGE_MAPPING[name]} />
}
