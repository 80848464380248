import React, { FC } from 'react'

import { graphql, useFragment } from 'react-relay'

import { Metadata } from '../Metadata'

import { GuildMetadata_guild$key } from './__generated__/GuildMetadata_guild.graphql'

import { HOST_NAME } from '@data/config/url'

export const GuildMetadata: FC<{
  guild: GuildMetadata_guild$key
  title?: string
  description?: string
}> = ({ guild, title, description }) => {
  const data = useFragment(
    graphql`
      fragment GuildMetadata_guild on Guild {
        slugId
        name
        description
      }
    `,
    guild
  )

  return (
    <Metadata
      title={title || data.name}
      description={description || data.description}
      imageKitParams='NONE'
      photoUrl={`${HOST_NAME}/social/guild/${data.slugId}/card.png?${Math.floor(
        Date.now() / (60 * 60 * 1000)
      )}`}
    />
  )
}
