import React, { useMemo, useState, useEffect } from 'react'
import { graphql, useLazyLoadQuery } from 'react-relay'

import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useMatch,
  useParams,
  useNavigate,
} from 'react-router-dom'

import { lazy as loadable } from '@loadable/component'

import { GuildMetadata } from '@components/Guild/GuildMetadata'
import { wrapSuspenseScreen } from '@components/SuspenseWrapper'
import { EmojiIcon } from '@components/EmojiIcon/EmojiIcon'

import { GuildCard } from '@newComponents/Guild/GuildCard'
import { Text } from '@newComponents/Text'
import { Icon } from '@newComponents/Icon'
import { Screen } from '@newComponents/Screen'
import { Grid } from '@newComponents/Grid'
import { Group } from '@newComponents/Group'
import { Link } from '@newComponents/Link'
import { IconWrapper } from '@newComponents/Icon/IconWrapper'

import { wrapSuspenseComponent } from '@newComponents/SuspenseWrapper'

import { YStack, XStack, Accordion } from 'tamagui'

import { Button } from '@newComponents'

import {
  GuildNavigatorQuery,
  GuildNavigatorQuery$data,
} from './__generated__/GuildNavigatorQuery.graphql'

const NavigationIcon = loadable(
  () => import('../../newComponents/Icon/icons/NavigationIcon')
)

const GuildNetworksScreen = wrapSuspenseScreen(
  loadable(
    () => import('../../screens/GuildNetworksScreen/GuildNetworksScreen')
  )
)

const GuildEventsScreen = wrapSuspenseScreen(
  loadable(() => import('../../screens/GuildEventsScreen/GuildEventsScreen'))
)

const GuildPresentationsScreen = wrapSuspenseScreen(
  loadable(
    () =>
      import('../../screens/GuildPresentationsScreen/GuildPresentationsScreen')
  )
)

const GuildMembersScreen = wrapSuspenseScreen(
  loadable(() => import('../../screens/GuildMembersScreen/GuildMembersScreen'))
)

const GuildDonationScreen = wrapSuspenseScreen(
  loadable(
    () => import('../../screens/GuildDonationScreen/GuildDonationScreen')
  )
)

const GuildDonationConfirmationScreen = wrapSuspenseScreen(
  loadable(
    () =>
      import(
        '../../screens/GuildDonationConfirmationScreen/GuildDonationConfirmationScreen'
      )
  )
)

const GuildInviteScreen = wrapSuspenseScreen(
  loadable(() => import('../../screens/GuildInviteScreen/GuildInviteScreen'))
)

const GuildSettingsNavigator = wrapSuspenseScreen(
  loadable(() => import('../GuildSettingsNavigator'))
)

const GuildJoinConfirmation = wrapSuspenseComponent(
  loadable(
    () => import('../../modals/GuildJoinConfirmation/GuildJoinConfirmation')
  )
)

export const GuildNavigator = () => {
  const params = useParams()

  const data = useLazyLoadQuery<GuildNavigatorQuery>(
    graphql`
      query GuildNavigatorQuery($id: String!) {
        nodeBySlugId(id: $id) {
          __typename

          ... on Guild {
            id
            slugId
            type

            myMembership {
              id
            }

            minimumDonationAmount
            donationCurrency

            ...GuildCardNew_guild
            ...GuildMetadata_guild
            ...GuildJoinConfirmation_guild
            ...GuildJoinConfirmationContent_guild

            totalNetworks: rankedNetworks(
              membershipStatus: ACCEPTED
              first: 1
            ) {
              edges {
                cursor
              }
            }

            totalGuilds: rankedNetworkGuilds(
              membershipStatus: ACCEPTED
              first: 1
            ) {
              edges {
                cursor
              }
            }

            totalEvents: events(first: 1) {
              edges {
                cursor
              }
            }

            totalPresentations: presentations(first: 1) {
              edges {
                cursor
              }
            }
          }
        }
      }
    `,
    { id: params.actorId || '' }
  )

  const typenameIsGuild = data.nodeBySlugId?.__typename === 'Guild'

  const initialRoute = useMemo(() => {
    if (!data?.nodeBySlugId || data.nodeBySlugId.__typename !== 'Guild') {
      return 'members'
    }

    if (
      data.nodeBySlugId.type === 'NETWORK' &&
      data.nodeBySlugId.totalGuilds.edges.length
    ) {
      return 'network'
    }

    if (data.nodeBySlugId.totalEvents.edges.length) {
      return 'events'
    }

    if (data.nodeBySlugId.totalPresentations.edges.length) {
      return 'presentations'
    }

    return 'members'
  }, [
    typenameIsGuild && data?.nodeBySlugId?.type,
    typenameIsGuild && data?.nodeBySlugId?.totalNetworks?.edges?.length,
    typenameIsGuild && data?.nodeBySlugId?.totalGuilds?.edges?.length,
    typenameIsGuild && data?.nodeBySlugId?.totalEvents?.edges?.length,
    typenameIsGuild && data?.nodeBySlugId?.totalPresentations?.edges?.length,
  ])

  const location = useLocation()

  const locationState = location.state as { backgroundLocation?: Location }

  if (data.nodeBySlugId?.__typename !== 'Guild') {
    // TODO: Display Not Found Page
    return null
  }

  return (
    <>
      <GuildMetadata guild={data.nodeBySlugId} />

      <Routes location={locationState?.backgroundLocation || location}>
        <Route path='settings/*' element={<GuildSettingsNavigator />} />

        <Route
          path='*'
          element={
            <GuildNavigatorSidebar key={params.actorId} data={data}>
              <Routes location={locationState?.backgroundLocation || location}>
                <Route
                  path='confirm'
                  element={<GuildJoinConfirmation guild={data.nodeBySlugId} />}
                />

                <Route path='network' element={<GuildNetworksScreen />} />

                <Route path='events/*' element={<GuildEventsScreen />} />

                <Route
                  path='presentations'
                  element={<GuildPresentationsScreen />}
                />
                <Route path='members' element={<GuildMembersScreen />} />
                <Route path='donate' element={<GuildDonationScreen />} />
                <Route
                  path='donate/confirm'
                  element={<GuildDonationConfirmationScreen />}
                />

                <Route path='invite' element={<GuildInviteScreen />} />

                <Route
                  path='*'
                  element={<Navigate to={initialRoute} replace />}
                />
              </Routes>
            </GuildNavigatorSidebar>
          }
        />
      </Routes>
    </>
  )
}

const GuildNavigatorCardSection = ({
  data,
}: {
  data: GuildNavigatorQuery$data
}) => {
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  // useMatch matches against location data which encodes any unicode slugs
  const actorIdMatch = encodeURIComponent(params.actorId as string)
  const networkMatch = useMatch(`/${actorIdMatch}/network`)
  const eventsMatch = useMatch(`/${actorIdMatch}/events/*`)
  const presentationsMatch = useMatch(`/${actorIdMatch}/presentations`)
  const membersMatch = useMatch(`/${actorIdMatch}/members`)

  const selectedIndex = useMemo<number>(() => {
    if (data.nodeBySlugId?.__typename !== 'Guild') {
      return 0
    }

    const hasEvents = !!data.nodeBySlugId.totalEvents.edges.length
    const hasPresentations = !!data.nodeBySlugId.totalPresentations.edges.length

    if (
      data.nodeBySlugId.type === 'NETWORK' &&
      data.nodeBySlugId.totalGuilds.edges.length
    ) {
      if (networkMatch) {
        return 0
      }

      if (eventsMatch) {
        return 1
      }

      if (presentationsMatch) {
        if (hasEvents) {
          return 2
        }

        return 1
      }

      if (membersMatch) {
        if (hasEvents && hasPresentations) {
          return 3
        }

        if (hasEvents || hasPresentations) {
          return 2
        }

        return 1
      }
    }

    if (eventsMatch) {
      return 0
    }

    if (presentationsMatch) {
      if (hasEvents) {
        return 1
      }

      return 0
    }

    if (membersMatch) {
      if (hasEvents && hasPresentations) {
        return 2
      }

      if (hasEvents || hasPresentations) {
        return 1
      }

      return 0
    }

    if (
      data.nodeBySlugId?.__typename === 'Guild' &&
      data.nodeBySlugId.type === 'GUILD' &&
      data.nodeBySlugId.totalNetworks.edges.length &&
      networkMatch
    ) {
      if (hasEvents && hasPresentations) {
        return 3
      }

      if (hasEvents || hasPresentations) {
        return 2
      }

      return 1
    }

    return 0
  }, [data, networkMatch, eventsMatch, presentationsMatch, membersMatch])

  const [accordionValue, setAccordionValue] = useState('')
  const [accordionLabel, setAccordionLabel] = useState(() => {
    if (networkMatch) {
      return 'Network'
    }

    if (eventsMatch) {
      return 'Events'
    }

    if (presentationsMatch) {
      return 'Presentations'
    }

    if (membersMatch) {
      return 'Members'
    }

    return 'Navigation'
  })

  useEffect(() => {
    if (networkMatch) {
      setAccordionValue('')
      return setAccordionLabel('Network')
    }

    if (eventsMatch) {
      setAccordionValue('')
      return setAccordionLabel('Events')
    }

    if (presentationsMatch) {
      setAccordionValue('')
      return setAccordionLabel('Presentations')
    }

    if (membersMatch) {
      setAccordionValue('')
      return setAccordionLabel('Members')
    }

    setAccordionLabel('Navigation')
  }, [
    networkMatch,
    eventsMatch,
    presentationsMatch,
    membersMatch,
    setAccordionLabel,
    setAccordionValue,
  ])

  useEffect(() => {
    if (!accordionValue) {
      return
    }

    const timeoutId = setTimeout(() => {
      setAccordionValue('')
    }, 10000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [accordionValue, setAccordionValue])

  if (data.nodeBySlugId?.__typename !== 'Guild') {
    return null
  }

  return (
    <YStack gap='$4' $gtXs={{ gap: '$8' }}>
      <GuildCard
        guild={data.nodeBySlugId}
        limitDescriptionLines={8}
        showSettingsButton={!!data.nodeBySlugId.myMembership}
        showMoreDialog
      />

      {!!data.nodeBySlugId.donationCurrency &&
        !!data.nodeBySlugId.minimumDonationAmount && (
          <Button
            successFlash={false}
            errorFlash={false}
            // secondary
            onPress={() => {
              const slugId =
                data.nodeBySlugId?.__typename === 'Guild' &&
                data.nodeBySlugId?.slugId

              if (slugId) {
                navigate(`/${slugId}/donate`, {
                  state: { backgroundLocation: location },
                })
              }
            }}
            paddingHorizontal='$5'
            paddingVertical='$2'
            justifyContent='flex-start'
            height={36}
          >
            <XStack alignItems='center' gap='$3'>
              <EmojiIcon name='sparkling-heart' size={5} />

              <Button.Text size='$5' fontWeight='$6'>
                Donate
              </Button.Text>
            </XStack>
          </Button>
        )}

      <Accordion
        type='single'
        overflow='hidden'
        collapsible
        animation='fast'
        // backgroundColor={accordionValue ? '$grey0' : '$lightBlue2'}
        // backgroundColor={accordionValue ? 'none' : '$lightBlue2'}
        backgroundColor='$grey0'
        // borderRadius='$6'
        borderRadius={18}
        boxShadow='$boxShadowLight'
        // boxShadow={
        //   accordionValue
        //     ? 'rgba(10, 37, 64, 0.16) 0px 8px 16px -4px, rgba(10, 37, 64, 0.12) 0px 10px 30px -7px'
        //     : '$boxShadowLight'
        // }
        value={accordionValue}
        onValueChange={(newValue) => {
          setAccordionValue(newValue)
        }}
        style={{
          willChange: 'box-shadow',
          transition: 'all 375ms cubic-bezier(0,0.5,1,1)',
        }}
        $gtXs={{ display: 'none' }}
        hoverStyle={{
          backgroundColor: accordionValue ? '$grey0' : '$lightBlue2',
        }}
        // $hoverNone={{
        //   hoverStyle: {
        //     boxShadow: accordionValue
        //       ? 'rgba(10, 37, 64, 0.16) 0px 8px 16px -4px, rgba(10, 37, 64, 0.12) 0px 10px 30px -7px'
        //       : '$boxShadowLight',
        //   },
        // }}
        // $pointerCoarse={{
        //   hoverStyle: {
        //     boxShadow:
        //       'rgba(10, 37, 64, 0.16) 0px 8px 16px -4px, rgba(10, 37, 64, 0.12) 0px 10px 30px -7px',
        //   },
        // }}
      >
        <Accordion.Item value='menu'>
          <Accordion.Trigger
            padding={0}
            borderWidth={0}
            backgroundColor='none'
            hoverStyle={{ backgroundColor: 'none' }}
            pressStyle={{ backgroundColor: 'none' }}
          >
            {({ open }: { open: boolean }) => (
              <XStack
                animation='medium'
                paddingHorizontal='$5'
                paddingVertical='$2'
                flexDirection='row'
                alignItems='center'
                borderWidth={0}
                // borderTopLeftRadius='$6'
                // borderTopRightRadius='$6'
                // borderBottomLeftRadius={open ? 0 : '$6'}
                // borderBottomRightRadius={open ? 0 : '$6'}
                // borderRadius='$6'
                // backgroundColor='$lightBlue2'
                gap='$3'
                // hoverStyle={{ backgroundColor: '$lightBlue2' }}
              >
                <IconWrapper color='$grey8'>
                  <NavigationIcon />
                </IconWrapper>

                {/* <Icon name='navigation' color='$grey8' /> */}

                {/* <YStack gap={0} height='100%' flex={1}>
                    <Text
                      color='$grey8'
                      fontWeight='$5'
                      position='absolute'
                      opacity={open ? 1 : 0}
                      animation='fast'
                    >
                      Navigation
                    </Text>

                    <Text
                      color='$grey8'
                      fontWeight='$5'
                      position='absolute'
                      opacity={open ? 0 : 1}
                      animation='fast'
                    >
                      {accordionLabel}
                    </Text>
                  </YStack> */}

                <Text color='$grey8' fontWeight='$5' flex={1}>
                  {open ? 'Navigation' : accordionLabel}
                </Text>

                <YStack
                  animation='mediumSpring'
                  rotate={open ? '180deg' : '0deg'}
                >
                  <Icon name='chevron-down' color='$grey8' />
                </YStack>
              </XStack>
            )}
          </Accordion.Trigger>

          <Accordion.HeightAnimator animation='fastSpring' width='100%'>
            <Accordion.Content
              animation='fast'
              // paddingHorizontal='$5'
              // paddingVertical='$1'
              // flexDirection='row'
              // alignItems='center'
              paddingHorizontal={0}
              paddingBottom={0}
              paddingTop='$3'
              borderWidth={0}
              backgroundColor='none'
              // gap='$1'
              exitStyle={{ opacity: 0 }}
              width='100%'
            >
              <GuildNavigation data={data} activeIndex={selectedIndex} />
            </Accordion.Content>
          </Accordion.HeightAnimator>
        </Accordion.Item>
      </Accordion>

      <GuildNavigation
        data={data}
        activeIndex={selectedIndex}
        display='none'
        $gtXs={{ display: 'flex' }}
      />
    </YStack>
  )
}

const GuildNavigatorSidebar = ({
  data,
  children,
}: {
  data: GuildNavigatorQuery$data
  children: React.ReactNode
}) => {
  if (data.nodeBySlugId?.__typename !== 'Guild') {
    return null
  }

  return (
    <Screen>
      <YStack gap='$8' flex={1} $gtXs={{ display: 'none' }}>
        <GuildNavigatorCardSection data={data} />

        {children}
      </YStack>

      <Grid display='none' $gtXs={{ display: 'flex' }} gap='$8'>
        <Grid.Column flex={5} $gtSm={{ flex: 4 }} $gtMd={{ flex: 3 }}>
          <YStack gap='$4' style={{ position: 'sticky', top: 84 }}>
            <GuildNavigatorCardSection data={data} />
          </YStack>
        </Grid.Column>

        <Grid.Column flex={5} $gtSm={{ flex: 8 }} $gtMd={{ flex: 9 }}>
          {children}
        </Grid.Column>
      </Grid>
    </Screen>
  )
}

const GuildNavigation = Group.styleable<{
  data: GuildNavigatorQuery$data
}>(({ data, ...props }, ref) => {
  const params = useParams()

  if (data.nodeBySlugId?.__typename !== 'Guild') {
    return null
  }

  let currentIndex = 0

  return (
    <Group
      {...props}
      ref={ref}
      gap='$2'
      highlightedElementProps={{ borderRadius: '$6' }}
    >
      {data.nodeBySlugId.type === 'NETWORK' &&
        !!data.nodeBySlugId.totalGuilds.edges.length && (
          <Group.Item index={currentIndex++}>
            <Link
              href={`/${params.actorId}/network`}
              hoverStyle={{ textDecorationLine: 'none' }}
              paddingHorizontal='$5'
              paddingVertical='$2'
            >
              <XStack alignItems='center' gap='$3'>
                <Icon name='organization' color='$grey8' />

                <Text color='$grey8' fontWeight='$5'>
                  Network
                </Text>
              </XStack>
            </Link>
          </Group.Item>
        )}

      {!!data.nodeBySlugId.totalEvents.edges.length && (
        <Group.Item index={currentIndex++}>
          <Link
            href={`/${params.actorId}/events`}
            hoverStyle={{ textDecorationLine: 'none' }}
            paddingHorizontal='$5'
            paddingVertical='$2'
          >
            <XStack alignItems='center' gap='$3'>
              <Icon name='calendar' color='$grey8' />

              <Text color='$grey8' fontWeight='$5'>
                Events
              </Text>
            </XStack>
          </Link>
        </Group.Item>
      )}

      {!!data.nodeBySlugId.totalPresentations.edges.length && (
        <Group.Item index={currentIndex++}>
          <Link
            href={`/${params.actorId}/presentations`}
            hoverStyle={{ textDecorationLine: 'none' }}
            paddingHorizontal='$5'
            paddingVertical='$2'
          >
            <XStack alignItems='center' gap='$3'>
              <Icon name='projection-screen' color='$grey8' />

              <Text color='$grey8' fontWeight='$5'>
                Presentations
              </Text>
            </XStack>
          </Link>
        </Group.Item>
      )}

      <Group.Item index={currentIndex++}>
        <Link
          href={`/${params.actorId}/members`}
          hoverStyle={{ textDecorationLine: 'none' }}
          paddingHorizontal='$5'
          paddingVertical='$2'
        >
          <XStack alignItems='center' gap='$3'>
            <Icon name='people-community' color='$grey8' />

            <Text color='$grey8' fontWeight='$5'>
              Members
            </Text>
          </XStack>
        </Link>
      </Group.Item>

      {data.nodeBySlugId.type === 'GUILD' &&
      data.nodeBySlugId.totalNetworks.edges.length ? (
        <Group.Item index={currentIndex++}>
          <Link
            href={`/${params.actorId}/network`}
            hoverStyle={{ textDecorationLine: 'none' }}
            paddingHorizontal='$5'
            paddingVertical='$2'
          >
            <XStack alignItems='center' gap='$3'>
              <Icon name='organization' color='$grey8' />

              <Text color='$grey8' fontWeight='$5'>
                Network
              </Text>
            </XStack>
          </Link>
        </Group.Item>
      ) : null}
    </Group>
  )
})

export default GuildNavigator
